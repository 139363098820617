
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'History'
})
export default class History extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private tableData=[]
  private loading = false

  page = 1
  size = 10
  total = 0

  get manholeId () {
    return this.$route.query.manholeId as string
  }

  created () {
    this.getList()
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    const info = this.searchInfo.dateRange ? {
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1]
    } : {}
    this.$axios.get(this.$apis.devices.getHistoryByTypeId('1022'),
      {
        ...info,
        objectId: this.manholeId,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getList()
  }
}
